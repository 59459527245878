<template>
  <FadeTransition>
    <div v-if="!objectIsEmpty(vehicleStore.carMakes)" class="define-vehicle-manual">
      <div>
        <h3 class="h3-size">{{ textResource('vehicleText', 'defineVehicleManually') }}</h3>
        <hr class="divider" />
      </div>
      <fieldset role="group" class="radio-buttons">
        <legend class="legend">
          <span class="label">{{ textResource('vehicleText', 'whatCarDoYouHave') }}</span>
        </legend>
        <ChipChoice
          v-for="item in vehicleStore.carMakes"
          :id="item.carMake.value"
          :key="item.carMake.value"
          v-model="state.make"
          :value="item.carMake.value"
          :label="item.carMake.name"
          name="vehicle-brand"
        >
          <div class="chip-choice-label">
            <Icon
              class="image-wrapper"
              :name="item.carMake.value.toLowerCase()"
              :placeholder-text="item.carMake.name"
            />
            <div>{{ item.carMake.name }}</div>
          </div>
        </ChipChoice>
      </fieldset>
      <fieldset>
        <legend class="legend">
          <span class="label">{{ textResource('vehicleText', 'carType') }}</span>
        </legend>
        <div class="car-type-selection">
          <Radiobutton
            :id="'passenger'"
            v-model:value="state.carType"
            variant="primary"
            size="extended"
            :input-value="'passenger'"
            :checked="state.carType == 'passenger'"
            :name="'cartype'"
          >
            <div>{{ textResource('vehicleText', 'car') }}</div>
          </Radiobutton>
          <Radiobutton
            :id="'transport'"
            v-model:value="state.carType"
            variant="primary"
            size="extended"
            :input-value="'transport'"
            :checked="state.carType == 'transport'"
            :name="'cartype'"
          >
            <div>{{ textResource('vehicleText', 'van') }}</div>
          </Radiobutton>
        </div>
      </fieldset>
      <InputField
        id="vehicle-model"
        ref="inputModel"
        v-model:value="state.model"
        class="vehicle-model"
        :label="textResource('vehicleText', 'model')"
        :placeholder="textResource('vehicleText', 'model')"
        :required="true"
        type="text"
        :labelinside="false"
      />
      <InputField
        id="vehicle-year"
        ref="inputYear"
        v-model:value="state.year"
        v-model:valid="state.isValidYear"
        :mask="'####'"
        class="vehicle-year"
        :label="textResource('vehicleText', 'modelYear')"
        :placeholder="textResource('vehicleText', 'modelYear')"
        type="number"
        :labelinside="false"
        pattern="^19[6-9][0-9]|20[0-3][0-9]"
        html-pattern="\d*"
        inputmode="numeric"
        :min="1960"
        :max="2039"
        :required="true"
      />
    </div>
  </FadeTransition>
</template>

<script lang="ts" setup>
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'
import { useVehicle } from '@/store/vehicle.store'
import textResource from '@/mixins/sharedFunctions/textResource'
import { reactive, watch } from 'vue'
import { EventBus } from '@/event-bus'
import userMonitoring from '@/mixins/userMonitoring'
import ChipChoice from '@/components/generic/ChipChoice.vue'
import InputField from '@/components/generic/InputField.vue'
import Icon from '@/components/generic/Icon.vue'
import Radiobutton from '@/components/generic/Radiobutton.vue'
import FadeTransition from '@/components/generic/FadeTransition.vue'
import { useService } from '@/store/service.store'
import { useBooking } from '@/store/booking.store'
const vehicleStore = useVehicle()
const serviceStore = useService()
const bookingStore = useBooking()

const state = reactive({
  make: '',
  model: '',
  year: 0,
  carType: 'passenger',
  isValidYear: false,
})

function createCarCode(makeValue: string) {
  const carMake = vehicleStore.carMakes.find((carMake) => {
    return carMake.carMake.value == makeValue
  })

  if (carMake?.carMake.carMakeCode == null) {
    return ''
  }

  return carMake && carMake.carMake.carMakeCode != ''
    ? `${carMake.carMake.carMakeCode}:${state.carType == 'passenger' ? 0 : 1}`
    : ''
}

function getCarMakeName(makeValue: string) {
  const carMake = vehicleStore.carMakes.find((carMake) => {
    return carMake.carMake.value == makeValue
  })
  return carMake ? carMake.carMake.name : ''
}

watch(
  () => ({ ...state }),
  function () {
    if (state.make && state.model && state.isValidYear) {
      vehicleStore.vehicle = {
        ...vehicleStore.vehicle,
        make: getCarMakeName(state.make),
        model: state.model,
        year: parseInt(state.year.toString()),
        siteCarMake: { value: state.make, code: createCarCode(state.make) },
        isTransportCar: state.carType == 'transport',
        hasTireHotel: false,
        typeHigh: state.carType == 'transport' ? 'LLB' : 'PB',
      }
      bookingStore.vehicle = vehicleStore.vehicle
      serviceStore.setup()
    } else {
      vehicleStore.vehicle = {
        ...vehicleStore.vehicle,
        make: '',
        model: '',
        year: 0,
        isTransportCar: state.carType == 'transport',
        siteCarMake: { ...vehicleStore.vehicle.siteCarMake, code: '' },
        typeHigh: state.carType == 'transport' ? 'LLB' : 'PB',
      }
    }
  }
)

function imgError(event: any) {
  console.log(event.target.src)
}

userMonitoring().trackAction('REGNUMBER - Open manual vehicle input')
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';

.chip-choice-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--font-s);
  height: 100%;
  width: 100%;
  row-gap: var(--size-half);
  padding: var(--size-full);
  text-align: center;
}

.define-vehicle-manual {
  display: grid;
  grid-row-gap: 1rem;
}

.label {
  margin-top: var(--size-half);
  font-size: var(--font-s);
  @include hellix-semibold;
}

.legend {
  margin-bottom: var(--size-half);
}

.divider {
  margin-top: var(--size-half);
  margin-bottom: 0;
  border-top: solid 2px var(--color-bilia-sand-100);
}

[role='group'] {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: center;
}

[class*='brand-'] :deep() label {
  border: solid 2px var(--color-bilia-white);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--color-bilia-white);
  box-shadow: 0px 0px 0.3rem rgba(0, 0, 0, 0.3);
  height: 4rem;
  min-width: 4rem;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.other-carmake,
.image-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(svg) {
    max-height: var(--size-double);
    width: 100%;
    display: block;
  }
}

.icon {
  word-break: break-word;
  line-height: 1rem;
  text-align: center;
}

fieldset {
  border: none;
  border-radius: 2px;
  margin: 0;
  padding: 0;
}

.car-type-selection {
  border: solid 1px var(--color-bilia-black);
}
</style>
